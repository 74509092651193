<template>
  <div class="coberturaSocial">
      <!-- CONTENIDO -->
    <div class="container">
         <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
      <div class="row top-buffer">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
            <li><a href="https://www.gob.mx/sct">SCT</a></li>
            <li>Portal Cobertura Universal</li>
            <li class="active">Programa de Cobertura Social</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7" id="logotipo">
          <img src="../assets/img/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom:30px;">Programa de Cobertura Social</h1>
          <hr class="red">
        </div>
      </div>

      <!-- CONTENIDO -->
      <div class="row">
        <div class="col-md-12">
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/PCS_2020-2021.pdf" download="PCS_2020-2021.pdf">Programa de Cobertura Social 2020-2021</a>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/20210122_PCS-Anexo_1_localidades_prioritarias.ods" download="PCS-Anexo_1_localidades_prioritarias.ods">Anexo 1 - Localidades Prioritarias</a>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/20210122_PCS-Anexo_2_base_total_localidades.ods" download="PCS-Anexo_2_base_total_localidades.ods">Anexo 2 - Base total de localidades</a>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/20210122_anexo_3_PCS_2020.ods" download="Anexo_3_PCS_2020.ods">Anexo 3 Programa de cobertura Social</a>
          
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-right">
          <hr />
          <a class="btn btn-default" type="button" href = '/cobertura-socialMenu'>Regresar</a>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import router from "../router"
export default {
  name: 'coberturaSocial',
   methods: {
    back(){
        router.replace('/')
    },
  },
}
</script>

